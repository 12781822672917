import * as React from "react";
import { graphql } from "gatsby"
import 'bootstrap/scss/bootstrap.scss'
import "../scss/global.scss";
import "../scss/grunticon.scss"
import "slick-carousel/slick/slick.css";
import "../scss/custom.scss";
import Layout from "../components/layout"
import HomeModules from "../components/homemodules"
import SEO  from "../components/seo"
import "../../src/components/StaticComponent/StaticComponent.scss"

// markup
const HomeTemplate = (props) => {

  const GQLPage = props.data?.glstrapi?.page
  const GQLModules = GQLPage?.add_components
  // const locationState = props.location.state

  var layout = GQLPage?.layout
    return (
      <div className={`home-page ${GQLPage.page_class ? GQLPage.page_class : ''}`}>
        
        <SEO title={GQLPage?.seo_meta?.title} description={GQLPage?.seo_meta?.description} noindex={GQLPage?.title==="Off Plan Results"?true:false}/>
        <Layout>
            <HomeModules {...GQLPage}/>
        </Layout> 
      </div>
    )
}
  
export default HomeTemplate
  
export const pageQuery = graphql`
  query GetArticleHome($id: ID!) {
    glstrapi {
      page(id: $id, publicationState: LIVE) {
        alias
        banner_content
        banner_cta_label
        banner_right_content
        banner_search_default_select
        choose_form
        content
        layout
        page_class
        show_reviews
        show_search
        title
        imagetransforms
        id
        seo_meta {
          title
          description
          keywords
          image {
            url
          }
        }
        menu {
          label
          id
        }
        banner_image {
          url
          alternativeText
          url_sharp {
            childImageSharp {
              gatsbyImageData(
                formats: WEBP
                width: 1800
                quality: 100
                layout: CONSTRAINED
                transformOptions: { cropFocus: CENTER, fit: COVER }
              ) 
            }
          }
        }
        banner_cta_link {
          id
          slug
        }

         add_components {
          __typename

          ... on GLSTRAPI_ComponentComponentsShowModule {
            id
            module {
              module_type
              section_name
              add_new_text_with_image_module {
                layout_type
                text_block {
                  description
                  title
                }
                media_block {
                  media_file {
                    url
                    alternativeText
                    url_sharp {
                      childImageSharp {
                        gatsbyImageData(
                          formats: WEBP
                          width: 500
                          quality: 100
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER, fit: COVER }
                        ) 
                      }
                    }
                  }
                }
                Button_CTA {
                  label
                  menu_item {
                    id
                  }
                  Secondary_Url
                }
              }
              add_new_stats_or_service {
                description
                icon_class_name
                title
                properties_count
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsShowFeaturedProperty {
            show
          }

          ... on GLSTRAPI_ComponentComponentsShowCustomerReviews {
            show
          }

          ... on GLSTRAPI_ComponentComponentsIntroSection {
            text_block {
              title
              description
              Main_Section_Title
            }
            link {
              id
              label
              menu_item {
                id
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsFullWidthTwoColumnBlock {
            column_1_cta_label
            column_1_sub_title
            column_1_title
            column_2_sub_title
            column_2_title
            column_1_cta_link {
              id
            }
            column_2_cta_label
            column_2_cta_link {
              id
            }
          }

          
        }

      }
    }
  }
`