import React from "react";
import Banner from "./Banner/Banner";
import Services from "./Services/Services";
import ServicesPlain from "./Services/ServicesPlain";
import AboutSection from "./AboutSection/AboutSection";
import RatingSection from "./RatingSection/RatingSection";
import Properties from "./Properties/Properties";
import FindProperty from "./FindProperty/FindProperty";
import BrowseProperty from "./BrowseProperty/BrowseProperty";
import HomeStructuredData from "./Seo/HomeStructuredData";
//import AboutStructuredData from "./Seo/AboutStructuredData";


const HomeModules = (props) => {
    return (
        <React.Fragment>
            { props?.title === "Home" && 
                <HomeStructuredData />                   
            } 
            {props.banner_image &&
                <Banner {...props} />
            }
            {
              props.add_components && props.add_components.map((module, i) => {
                    return (
                        <>
                            {module.__typename === "GLSTRAPI_ComponentComponentsShowModule" && module.module &&
                                <> {module.module.module_type == "text_with_image" ?<BrowseProperty {...module} />:module.module.module_type == "stats_or_services" ?<Services {...module} /> : 
                                    module.module.module_type == "stats_white_background" ? 
                                    <ServicesPlain {...module} /> : ''
                                    }
                                </>
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsShowFeaturedProperty" &&
                                <FindProperty {...module} />
                            }
                            {module.__typename === "GLSTRAPI_ComponentComponentsShowCustomerReviews" &&
                                <RatingSection />
                            }
                            {module.__typename === "GLSTRAPI_ComponentComponentsIntroSection" &&
                                <AboutSection {...props} {...module} />
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsFullWidthTwoColumnBlock" &&
                                <Properties {...module} />
                            }
                        </>
                    )
                })
            }

        </React.Fragment>
    )
}
export default HomeModules
